<!-- 产品中心 -->
<template>
  <div class="business gn-box-border">
    <div class="banner pc-banner">
      <img class="img2" :src="bannerUrl.title_img" alt="" />
    </div>
    <!-- 短视频素材有奖征集 -->
    <div class="solicitation">
      <div class="solicitation-main">
        <div class="left">
          <div class="left-header">
            短视频素材有奖征集
          </div>
          <div class="left-text">
            <div class="mar-but">为宣传广东农资行业40年改革建设成果，积极推动广东省农资行业数字化升级，本协会计划推出“中国农资市场风云40年“系列短视频特辑，并面向全社会展开内容素材有奖征集。</div>
            <div>本次征集活动围绕“中国农资市场风云40年”，不间断面向全国农资人征集符合主题要求的优质原创内容，题材涉及厂家、经销商、农资店三方角色的真实发迹史、经典营销案例、商业模型和背后鲜为人知的故事等。</div>
          </div>
        </div>
        <div class="right-img">
          <img v-if="windowWidth >768" src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20241615/17157717603280643473" alt="">
          <img v-else src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/d3bb2cadb2404fe5bcfb4323ba5e0cad.jpg" alt="">

        </div>
      </div>
    </div>
    <!-- 征集时间 -->
    <div class="artical">
      <div class="artical-main">
        <div class="main-block main-block-header">
          <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/fccda02636da43bab3d86e12dc751f39.png" alt="">
          <div class="block">
            <div class="block-title">征集时间</div>
            <div class="block-text">长期有效</div>
          </div>
        </div>
        <div class="main-block main-block-header main-block-header-tow">
          <img class="block-img" src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/5cdbdeae5cf049379b542363f59d2caf.png" alt="">
          <div class="block">
            <div class="block-title">投稿形式</div>
            <div class="block-text">文字，图片，视频，形式不限</div>
          </div>
        </div>
        <div class="main-block main-artical">
          <div class="main-artical-img">征集范围</div>
          <div class="main-artical-text">
            面向全国，作品要求以农资市场、农资人、农资营销、农资企业兴衰等农资行业题材，展示40年中国农资市场的风云百态。
          </div>
        </div>
        <div class="main-block main-artical main-artical-tow">
          <div class="main-artical-img">投稿要求</div>
          <div class="main-artical-text tow">
            1.内容积极正面，题材类型不限，形式不限，视频类优先。<br>
            2.投稿内容要求为原始素材，视频的分辨率在720p及以上。
          </div>
        </div>
        <div class="main-block main-footer">
          <div class="main-artical-img">活动细则</div>
          <div class="footer-artical-text">
            1、投稿素材须为原创，不侵犯第三者著作权、肖像权、名誉权、隐私权、商标权等其它合法权益。<br>
            2、素材一经选中，参与者需配合主办方提供短视频内使用的原始素材。<br>
            3、凡是本次活动的参与者皆视为同意并遵守以上规则，如未按照以上规则执行的，由其承担全部责任。
          </div>
        </div>
        <div class="main-block main-footer main-footer-tow">
          <div class="main-artical-img">奖励说明</div>
          <div class="footer-artical-text right" v-if="windowWidth >768">
            所提供内容素材一经采纳，被应用到本次系列视频特辑中，<br>
            协会将给予投稿者一定现金奖励，具体视素材价值而定。
          </div>
          <div class="footer-artical-text right" v-else>
            所提供内容素材一经采纳，被应用到本次系列视频特辑中，协会将给予投稿者一定现金奖励，具体视素材价值而定。
          </div>
        </div>
      </div>
    </div>
    <div class="submission">
        <img v-if="windowWidth >768" src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/ee598226155a457482e0df4cc8bda81d.png" alt="">
        <img v-else src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/c920f399bf1441e58587b804627b693d.png" alt="">

        <div class="submission-ing">投稿方式</div>
        <div class="submission-text" v-if="windowWidth >768">投稿请联系： 文经理 13422276741(微信同号)</div>
        <div class="submission-text" v-else>投稿请联系： <br>文经理 13422276741(微信同号)</div>

      </div>
    <!-- 精彩视频合集 -->
    <div class="videoblock">
      <div class="videoblock-title" v-if="UseVideosList.length > 0">精彩视频合集</div>
      <swiper v-if="windowWidth > 768" class="swiper" ref="mySwiper" :key="updateKey" :options="swiperOptionOther">
        <swiper-slide
          class="achievements-list"
          v-for="(item, index) in UseVideosList"
          :key="item.create_time"
        >
          <div class="achievements-item" @click="goVideo(item)">
            <div class="img">
              <img :src="item.title_image" />
            </div>
            <div class="certificate" >{{ item.title }}</div>
          </div>
        </swiper-slide>
        <!-- 分页器 -->
        <!-- <div class="swiper-pagination" slot="pagination"></div> -->
        <!-- 左右按钮 -->
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
      <!-- 手机版 -->
      <div class="dynamic" v-else>
        <div class="dynamic-main">
          <div class="main_item" v-for="(item,index) in UseVideosList" :key="index" @click="goVideo(item)">
            <img :src="item.title_image" alt="">
            <div class="item_title">{{ item.title }}</div>
          </div>
        </div>
        <div class="more" @click="goMore" v-if="isShowMore">
          加载更多
          <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20240918/17159981748505699804" alt="">
        </div>
        <div v-else class="more"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getArticleList, getBannerList } from '@/api/admin'

export default {
  name: 'business',
  data () {
    return {
      bannerUrl: {
        title_img: 'https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/f22d54cb7e204f72904108359393c4d7.png'
      },
      UseVideosList: [],
      updateKey: +new Date(),
      swiperOptionOther: {
        observer: true,
        slidesPerView: 3,
        spaceBetween: 43,
        pagination: {
          el: '.swiper-pagination',
          clickable: false
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // 设置自动轮播
        autoplay: {
          delay: 5000, // 5秒切换一次
          // 用户操作swiper以后，是否禁止autoplay.默认为true中止
          disableOnInteraction: false
        },
        // 设置轮播可循环
        loop: false
      },
      reqParams: {
        page: 1,
        per_page: 10,
        search_data: '', // 关键字搜索
        advisory_type: '3', // 类型 1 公司新闻 2 行业动态 3 视频展示
        status: '', // 0 正常 1 停用 2 删除
        start_time: '', //  开始时间
        end_time: '', // 结束时间
        is_platform: '1'
      },
      windowWidth: 1024,
      isShowMore: true

    }
  },
  created () {
    this.getbannerList()
  },
  mounted () {
    window.addEventListener('resize', this.resizePage)
    this.windowWidth = window.document.body.clientWidth
    if (this.windowWidth < 768) {
      this.reqParams.per_page = 2
    }
    this.getList()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizePage)
  },
  watch: {
    // 监听,当路由发生变化的时候执行

  },
  methods: {
    goMore () {
      this.reqParams.page++
      this.getList()
    },
    // 页面大小改变
    resizePage () {
      this.windowWidth = window.document.body.clientWidth
    },
    // 获取 最新动态 的视频列表
    async getList () {
      const { list } = await getArticleList(this.reqParams)
      if (list.length < this.reqParams.per_page) {
        this.isShowMore = false
      }
      if (this.reqParams.page === 1) {
        this.UseVideosList = list || []
      } else {
        this.UseVideosList = [...this.UseVideosList, ...list]
      }
      console.log(this.UseVideosList, 'smdx')
    },
    getbannerList () {
      const obj = {
        page: 1,
        per_page: 200
      }
      getBannerList(obj).then(res => {
        console.log('banner', res)
        for (let i = 0; i < res.length; i++) {
          if (res[i].title === '有奖征集') {
            this.bannerUrl = res[i].list[0]
            break
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    goVideo (item) {
      this.$router.push({
        name: 'video',
        query: { ...item }
      })
    }
  }
}
</script>

<style scoped lang="scss">

.business {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  margin-top: 65px;
  box-sizing: border-box;
  .banner{
    .img1{
      display: none;
    }
    .img2{
      width: 100%;
      height: 500px;
    }
  }
  .solicitation{
    width: 1200px;
    height: 478px;
    margin: 0 auto;
    margin-top: 43px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    .solicitation-main{
      padding: 24px 75px 24px 40px;
      display: flex;
      width: 100%;
      height: 100%;
      .left{
        flex: 1;
        .left-header{
          width: 328px;
          height: 46px;
          background: #1b5ee8;
          font-size: 32px;
          font-family: PingFang SC, PingFang SC-400;
          font-weight: 400;
          text-align: center;
          color: #ffffff;
          line-height: 46px;
          margin-top:42px;
        }
        .left-text{
          width: 691px;
          height: 316px;
          font-size: 20px;
          font-family: PingFang SC, PingFang SC-400;
          font-weight: 400;
          text-align: LEFT;
          color: #000000;
          line-height: 36px;
          margin-top: 30px;
          padding-left: 9px;
          div{
            text-indent:40px;
          }
          .mar-but{
            margin-bottom: 40px;
          }
        }
      }
      .right-img{
        margin-left: 82px;
        width: 306px;
        height: 433px;
        border: 1px solid #e7e7e7;
        img{
            width: 303px;
            height: 430px;
          }
      }
    }
  }
  .artical{
    width:1200px;
    margin: 0 auto;
    margin-top:66px;
    .artical-main{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .main-block{
        width: 580px;
        height: 194px;
        border-radius: 10px;
      }
      .main-artical-img{
          width: 177px;
          height: 57px;
          border-radius: 0px 0px 5px 5px;
          margin: -5px 0 0 23px;
          background: url('https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/ee598226155a457482e0df4cc8bda81d.png');
          text-align: center;
          font-size: 30px;
          font-family: PingFang SC, PingFang SC-400;
          font-weight: 400;
          color: #ffffff;
          line-height: 57px;
        }
      .main-block-header{
        display: flex;
        align-items: center;
        padding-left: 69px;
        margin-bottom: 46px;
        border: 1px solid #d9d9d9;
        .block-img{
          width: 75px;
          height: 54px;
        }
        .block{
          display: flex;
          flex-direction: column;
          font-family: PingFang SC, PingFang SC-400;
          font-weight: 400;
          color: #333333;
          margin-left: 55px;
          .block-title{
            font-size: 30px;
            text-align: left;
            line-height: 30px;
          }
          .block-text{
            font-size: 24px;
            color: #666666;
            line-height: 36px;
            margin-top: 16px;
          }
        }
        img{
          width: 67px;
          height: 67px;
        }
      }
      .main-artical{
        background-color: #fff;
        height: 240px;
        margin-bottom: 46px;

        .main-artical-text{
          padding: 39px 32px 0 31px;
          font-size: 20px;
          font-family: PingFang SC, PingFang SC-400;
          font-weight: 400;
          text-align: LEFT;
          color: #666666;
          line-height: 36px;
        }
        .tow{
          padding-right: 0;
        }
      }
      .main-footer{
        background-color: #fff;
        height: 353px;
        .footer-artical-text{
          padding: 33px 45px 0 30px;
          font-size: 20px;
          font-family: PingFang SC, PingFang SC-400;
          font-weight: 400;
          text-align: LEFT;
          color: #666666;
          line-height: 36px;
        }
        .right{
          padding-right: 0;
          img{
            // border: 1px solid #999;
          }
        }
      }
    }
  }
  .videoblock{
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 80px;
    .videoblock-title{
      margin: 68px 0 46px 0;
      font-size: 32px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      text-align: CENTER;
      color: #000000;
      line-height: 38px;
    }
    .achievements-list {
      box-sizing: border-box;
      height: 374px;
      background: #ffffff;
      border-radius: 5px;
      box-shadow: 0px 0px 15.5px 4px rgba(51,51,51,0.04);
      .achievements-item {
        height: 246px;
        box-sizing: border-box;
        .img {
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .certificate {
          font-size: 16px;
           font-family: PingFang SC, PingFang SC-400;
           font-weight: 400;
           text-align: LEFT;
           color: #333333;
           line-height: 24px;
           padding: 19px 30px 0 30px ;
           cursor: pointer;
        }
      }
    }
    .swiper-button-prev , .swiper-button-next{
      width: 40px;
      height: 75px;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.4); /* 使用半透明色遮罩 */
      top: 50%;
      margin-top: -90px;
    }
    .swiper-button-prev{
      left: 0px;
    }
    .swiper-button-next{
      right: 0px;
    }
  }
  .submission{
    margin: 0 auto;
    width: 1200px;
    height: 168px;
    margin-top: 40px;
    background-color: #fff;
    border-radius: 10px;
    img{
       width: 177px;
       height: 57px;
       margin: -5px 0 0 23px;

     }
    .submission-ing{
      width: 177px;
      height: 57px;
      border-radius: 0px 0px 5px 5px;
      // background: url('https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/ee598226155a457482e0df4cc8bda81d.png');
      text-align: center;
      font-size: 30px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      color: #ffffff;
      margin: -50px 0 0 23px;

    }
    .submission-text{
      text-align: center;
      font-size: 40px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      color: #1b5ee8;
      margin-top: 10px;
    }
  }
}
</style>

<style scoped lang="scss">
// 移动端 样式
@import "./media";
</style>
